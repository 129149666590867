<template>
    <modal-crud
        title="Novo Token"
        v-on:close="$emit('close')"
        :isLoading=isLoading>
        <template v-if="token">
            <section class="modal-card-body">
                <div class="columns">
                    <div class="column is-one-third">
                        <attribute title="ID">
                            {{ token.id }}
                        </attribute>
                    </div>
                    <div class="column">
                        <attribute title="Nome">
                            {{ token.name }}
                        </attribute>
                    </div>
                </div>
                <div class="columns">
                    <div class="column">
                        <attribute title="Secret">
                            {{ token.secret }}
                        </attribute>
                    </div>
                </div>
                <div class="columns">
                    <div class="column">
                        <b-message type="is-warning">
                            <b>OBS:</b> Anote o <b>Secret</b> antes de fechar a tela, uma vez fechada não será mais possível obte-la
                        </b-message>
                    </div>
                </div>
            </section>
        </template>
        <template v-else>
            <section class="modal-card-body">
                <b-field label="Nome">
                    <b-input
                        type="text"
                        placeholder="Nome"
                        v-model="name"
                        required>
                    </b-input>
                </b-field>
            </section>
            <div slot="buttons">
                <b-button
                    label="Salvar"
                    type="is-primary"
                    @click="save" />
            </div>
        </template>
    </modal-crud>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import ModalCrud from '@/components/Layout/ModalCrud.vue';
import state from '@/modules/auth/store/state';
import services from '@/http';
import Attribute from '@/components/Layout/Attribute.vue';

@Component({
    components: {
        "modal-crud":ModalCrud,
        Attribute
    },
})

export default class Create extends Vue { 
    @Prop({default: null}) token

    data() {
        return {
            name: null,
            isLoading: false,
        }
    }

    save() {
        var data = {
            name: this.name,
            userId: state.user.id           
        }

        this.isLoading = true;

        services.token.register(data)
            .then(res => {
                this.token = res.body.data         

                this.$root.flashSuccess('Adicionado com sucesso!')
            })
            .catch(err => {
                this.$root.flashError(err)
            })
            .finally(()=>{
                this.isLoading = false;
            });
    }
}
</script>