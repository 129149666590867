<template>
    <layout 
        title="Perfil" 
        subtitle="Dados do perfil"
        titleIcon="id-card"
    >
        <div class="box mt-5">
            <h4 class="title is-6">Informações da aplicação</h4>
            <div class="columns">
                <div class="column">
                    <attribute title="ID">
                        {{ resource.PerfilID }}
                    </attribute>
                </div>
                <div class="column">
                    <attribute title="Título">
                        {{ resource.Titulo }}
                    </attribute>
                </div>
            </div>

            <div class="mb-5 is-flex is-justify-content-end">
                <b-button
                    label="Víncular Escopo"
                    type="is-primary"
                    size="is-medium"
                    @click="linkScope" />
            </div>
        </div>

        <!-- Scopes -->
        <div v-if="resource.scopes" class="box mt-5">
            <h4 class="title is-6">Escopos</h4>

            <b-table 
                v-if="resource.scopes.length > 0"
                :data="resource.scopes" 
                :striped="true" 
                :hoverable="true" >

                <b-table-column label="Nome" v-slot="props">
                    {{ props.row.scope }}
                </b-table-column>
                
                <b-table-column label="Status" v-slot="props">
                    <b-tag v-if="props.row.status == 1" type="is-success" rounded>Ativo</b-tag>
                    <b-tag v-else type="is-danger" rounded>Inativo</b-tag>
                </b-table-column>

                <b-table-column label="Data criação" v-slot="props">
                    <strong>{{ new Date(props.row.created_at).toLocaleDateString("pt-BR") }}</strong>
                </b-table-column>
            </b-table>

            <div v-else>
                <div class="notification is-warning">
                    <i class="fa-solid fa-circle-info fa-fw mr-1"></i> Nenhum ambiente vinculado a esta aplicação.
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
import Attribute from '@/components/Layout/Attribute.vue';
import LinkScopeView from './LinkScope.vue';

@Component({
    components: {
        Attribute
    },
})

export default class Show extends Vue {
    data() {
        return {
            id: this.$route.params.id,
            resource: null, 
            isLoading: false,
        }
    }

    mounted() {
        this.isLoading = true;

        this.get()
    }

    get() {
        this.isLoading = true;

        this.$http.post('v1/movida/profile/show/' + this.id)
            .then(res => {
                this.resource = res.data.data
            }).catch(err => {
                this.isLoading = false;
                
                this.flash('danger', err.body.message)
            })
            .finally(()=>{
                this.isLoading = false;
            });
    }

    linkScope() {
        this.$buefy.modal.open({
            parent: this,
            component: LinkScopeView,
            props: {
                title: 'Víncular Escopo',
                profile: this.resource
            },
            hasModalCard: true,
            trapFocus: true,
            events: {
                'refreshList': value => {
                    this.get()
                }
            }
        })
    }
}
</script>
