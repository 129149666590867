import services from '@/http';
import * as types from './mutation-types';
import { userAuth } from '@/modules/auth';

export const ActionLoadSession = ({ commit, dispatch }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const user    = await services.auth.loadSession()
      const scopes  = await services.profile.scopes()

      commit(types.SET_USER, user.data.data)
      commit(types.SET_USER_SCOPES, scopes.data.data)

      resolve()
    } catch (err) {
      dispatch('ActionSignOut')

      reject(err)
    }
  })
}

export const ActionSetToken = ({commit}, payload) => {
  commit(types.SET_TOKEN, payload.token)
}

export const ActionSetAppToken = ({commit}, payload) => {
  commit(types.SET_APP_TOKEN, payload.token)
}

export const ActionSignOut = ({commit}) => {
  commit(types.SET_USER, {})
  commit(types.DELETE_TOKEN)
  
  userAuth.deleteToken()
}