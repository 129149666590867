import { routes as auth } from '../modules/auth';
import { routes as application } from '../modules/application';
import { routes as token } from '../modules/token';
import { routes as profile } from '../modules/profile';
import { routes as scope } from '../modules/scope';

export default [
    ...auth,
    ...profile,
    ...application,
    ...token,
    ...scope
]