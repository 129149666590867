import index from './pages/Index'
import show from './pages/Show';

export default [
    {
        path: '/application',
        name: 'application',
        meta: {
            permission: 'application.list',
            title: "Aplicações"
        },
        component: index
    },
    {
        path: '/application/:id',
        name: 'application.show',
        component: show,
        meta: {
            permission: 'application.show',
            title: "Editar aplicação"
        }
    }
];