require('./plugins');

import Vue from 'vue'
import VueCookies from 'vue-cookies';
import App from './App.vue'
import router from './router'
import store from './store'
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import '@fortawesome/fontawesome-free/css/all.css'
import PrimeVue from 'primevue/config';

var flash = {
    methods: {
        flash(type, message) {            
            this.$buefy.toast.open({
                message: message, 
                type: 'is-' + type, 
            })
        },
        flashSuccess(message) {            
            this.$buefy.toast.open({
                message: message, 
                type: 'is-success', 
            })
        },
        flashError(err) { 
            var message = "Ocorreu um erro!"
            
            if (err.body.message) {
                var error = "";

                if (err.body.message instanceof Object === true) {
                    error = Object.values(err.body.message)[0];
                } else {
                    error = err.body.message;
                }

                message = "Ocorreu um erro: " + error;
            } 
              
            this.$buefy.toast.open({
                message: message, 
                type: 'is-danger', 
            })
        }
    }
};

// Layouts
import Layout from '@/components/Layout/Layout.vue';
import TableDefault from '@/components/Layout/TableDefault.vue';

Vue.component('layout', Layout);
Vue.component('table-default', TableDefault);

Vue.config.productionTip = false
Vue.use(VueCookies, { expires: '1d'})
Vue.use(PrimeVue)

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    new BrowserTracing({ 
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: ["localhost", process.env.VUE_APP_SENTRY_TARGET, /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

new Vue({
    mixins:[flash],
    router,
    store,
    render: h => h(App)
}).$mount('#app')