export const hasToken = (state) => {
  return !!state.token;
}

export const user = (state) => {
  return state.user
}

export const scopes = (state) => {
  return state.user.scopes
}