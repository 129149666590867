import index from './pages/Index';
import show from './pages/Show';

export default [
    {
        path: '/token',
        name: 'token.index',
        meta: {
            title: "Tokens",
            permission: 'token.list'
        },
        component: index
    },
    {
        path: '/token/:id',
        name: 'token.show',        
        meta: {
            title: "Token",
            permission: 'token.show'
        },
        component: show,
    }
];