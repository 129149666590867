<template>
  <modal-crud
    title="Editar Escopo"
    v-on:close="$emit('close')"
    :isLoading=isLoading>
    <section class="modal-card-body">
      <b-field label="Nome">
        <b-input
          type="text"
          placeholder="Nome"
          v-model="scope.scope"
          required>
        </b-input>
      </b-field>
    </section>
    <div slot="buttons">
      <b-button
          label="Salvar"
          type="is-primary"
          @click="update" />
    </div>
  </modal-crud>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import ModalCrud from '@/components/Layout/ModalCrud.vue';
import state from '@/modules/auth/store/state';
import services from '@/http';

@Component({
    components: {
        "modal-crud":ModalCrud,
    },
})

export default class Edit extends Vue { 
  @Prop({default: {}}) scope

  data() {
    return {
      aplicacoes: [],
      isLoading: false,
    }
  }

  update() {
    var data = {
      aplicacao_id: this.scope.aplicacao_id,
      id: this.scope.id,
      scope: this.scope.scope,
      userId: state.user.id             
    }

    services.scope.edit(data)
      .then(res => {
        this.$root.flashSuccess('Alterado com sucesso!')

        this.$emit('close');               
        this.$emit('refreshList');               
      }).catch(err => {
        this.$root.flashError(err)
      });
  }
}
</script>