import index from './pages/Index';
import show from './pages/Show';

export default [
    {
        path: '/scope',
        name: 'scope.index',
        meta: {
            title: "Escopos",
            permission: 'scope.list'
        },
        component: index
    },
    {
        path: '/scope/:id',
        name: 'scope.show',        
        meta: {
            title: "Escopo",
            permission: 'scope.show'
        },
        component: show,
    }
];