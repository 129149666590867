<template>
    <layout 
        :title="resource.scope" 
        :isLoading="isLoading"
        subtitle="Escopo"
        titleIcon="id-card"
    >
        <div class="box mt-5">
            <h4 class="title is-6">Informações do token</h4>
            <div class="columns">
                <div class="column">
                    <attribute title="ID">
                        {{ resource.id }}
                    </attribute>
                </div>
                <div class="column">
                    <attribute title="Descrição">
                        {{ resource.scope }}
                    </attribute>
                </div>
                <div class="column">
                    <attribute title="Ativo?">
                        {{ resource.revoked ? 'Não' : 'Sim' }}
                    </attribute>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <attribute title="Adicionado em">
                        {{ new Date(resource.created_at).toLocaleDateString("pt-BR") }}
                    </attribute>
                </div>
                <div class="column">
                    <attribute title="Alterado em">
                        {{ new Date(resource.updated_at).toLocaleDateString("pt-BR") }}
                    </attribute>
                </div>
            </div>
        </div>

    </layout>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
import Attribute from '@/components/Layout/Attribute.vue';
import linkApplicationView from './LinkApplication.vue';
import linkScopeView from './LinkScope.vue';
import services from '@/http';

@Component({
    components: {
        Attribute
    },
})

export default class Show extends Vue { 
    data() {
        return {
            id: this.$route.params.id,
            resource: null, 
            isLoading: false,
        }
    }

    mounted() {
        this.get()
    }

    get() {
        this.isLoading = true;

        this.$http.post('v1/movida/scope/show/' + this.id)
            .then(res => {
                this.resource = res.data.data
            }).catch(err => {
                this.isLoading = false;
                
                this.flash('danger', err.body.message)
            })
            .finally(()=>{
                this.isLoading = false;
            });
    }
}
</script>