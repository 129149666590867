<template>
    <layout 
        title="Perfis" 
        subtitle="Listagem de perfis do sistema movida"
        titleIcon="users"
        :isLoading=isLoading
    >
        <table-default
            :data="resources"
            :columns="columns"
            @edit="edit"
            >
            <template v-for="column in columns">
                <b-table-column :key="column.PerfilID" v-bind="column" sortable>
                    <template
                        v-if="column.searchable && !column.numeric"
                        #searchable="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            placeholder="Pesquisar..." />
                    </template>
                    <template v-slot="props">
                        <span>
                            <router-link :to="('/profile/' + props.row.PerfilID)">
                                {{ props.row[column.field] }}
                            </router-link>
                        </span>
                    </template>
                </b-table-column>
            </template>
            <b-table-column v-slot="props" field="" label="Ações" sortable>
                <b-dropdown aria-role="list">
                    <template #trigger="{ }">
                        <b-button
                            label="..."
                            type="is-text"
                            />
                    </template>
                    <b-dropdown-item class="has-text-left" aria-role="listitem"><router-link :to="'/profile/' + props.row.id">Visualizar</router-link></b-dropdown-item>
                </b-dropdown>
            </b-table-column>
        </table-default>
    </layout>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
import TableDefault from '@/components/Layout/TableDefault.vue';
import services from '@/http';

@Component({
    components: {
        TableDefault
    },
})

export default class Index extends Vue { 
    data() {
        return {
            resources: [],
            columns: [
                {
                    field: 'PerfilID',
                    label: 'ID',
                    width: '100',
                    numeric: true,
                    searchable: true,
                },
                {
                    field: 'Titulo',
                    label: 'Nome',
                    searchable: true,
                }
            ],
            isLoading: false
        }
    }

    mounted() {
        this.list()
    }

    list() {
        this.isLoading = true;

        services.profile.list()
            .then(res => {
                this.resources = res.data.data;
            }).catch(err => {
                this.$root.flashError(err)
            })
            .finally(()=>{
                this.isLoading = false;
            });
    }

    create() {
        this.$buefy.modal.open({
            parent: this,
            component: CreateView,
            hasModalCard: true,
            trapFocus: true,
            events: {
                'close': value => {
                    this.list()
                }
            }
        })
    }

    edit(data) {
        this.$buefy.modal.open({
            parent: this,
            component: EditView,
            props: {
                aplicacao: data
            },
            hasModalCard: true,
            trapFocus: true,
            events: {
                'refreshList': value => {
                    this.list()
                }
            }
        })
    }
}
</script>
