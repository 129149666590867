<template>
    <modal-crud
        :title=title
        v-on:close="$emit('close')"
        :isLoading=isLoading>
        <section class="modal-card-body">
            <pick-list v-model="resources" listStyle="height:342px" dataKey="id">
                <template #sourceheader>
                    Available
                </template>
                <template #targetheader>
                    Selected
                </template>
                <template #item="slotProps">
                    <div class="p-caritem">
                        <div>
                            <span>{{slotProps.item.id}} - {{slotProps.item.scope}}</span>
                        </div>
                    </div>
                </template>
            </pick-list>
        </section>
        <div slot="buttons">
            <b-button
                label="Salvar"
                type="is-primary"
                @click="sync" />
        </div>
    </modal-crud>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import ModalCrud from '@/components/Layout/ModalCrud.vue';
import state from '@/modules/auth/store/state';
import services from '@/http';
import PickList from 'primevue/picklist';
import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";

@Component({
    components: {
        "modal-crud":ModalCrud,
        "pick-list":PickList,
    },
})

export default class LinkApplication extends Vue { 
    @Prop({default: {}}) token
    @Prop({default: ''}) title

    data() {
        return {
            isLoading: false,
            ambientesSelecionados: [],
            resources: []
        }
    }

    mounted() {
        this.isLoading = true;

        this.getScopes()
    }

    getScopes() {
        this.isLoading = true;

        services.application.listScope()
            .then(res => {
                this.isLoading  = false;
                this.resources = [res.data.data, []];
            }).catch(err => {
                this.isLoading = false;
                
                this.flash('danger', err.body.message)
            })
            .finally(()=>{
                this.isLoading = false;
            });
    }

    sync() {
        var data = {
            client_id: this.token.id,
            scopes: this.resources[1],
            userId: state.user.id            
        }

        this.isLoading = true;

        services.token.linkScope(data)
            .then(res => {
                this.$root.flashSuccess('Vínculado com sucesso!')

                this.$emit('close');               
                this.$emit('refreshList');               
            }).catch(err => {
                this.$root.flashError(err)
            })
            .finally(()=>{
                this.isLoading = false;
            });
    }
}
</script>