<template>
    <layout 
        :title="resource.name" 
        :isLoading="isLoading"
        subtitle="Tokens"
        titleIcon="rocket"
    >
        <div class="box mt-5">
            <h4 class="title is-6">Informações do token</h4>
            <div class="columns">
                <div class="column">
                    <attribute title="ID">
                        {{ resource.id }}
                    </attribute>
                </div>
                <div class="column">
                    <attribute title="Descrição">
                        {{ resource.name }}
                    </attribute>
                </div>
                <div class="column">
                    <attribute title="Ativo?">
                        {{ resource.revoked ? 'Não' : 'Sim' }}
                    </attribute>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <attribute title="Adicionado em">
                        {{ new Date(resource.created_at).toLocaleDateString("pt-BR") }}
                    </attribute>
                </div>
                <div class="column">
                    <attribute title="Alterado em">
                        {{ new Date(resource.updated_at).toLocaleDateString("pt-BR") }}
                    </attribute>
                </div>
            </div>
        </div>

        <div class="b-actions mb-5 is-flex is-justify-content-end">
            <b-button
                label="Víncular Aplicação"
                type="is-primary"
                size="is-medium"
                @click="linkApplication" />
        </div>

        <!-- Ambientes -->
        <div v-if="resource.ambientes" class="box mt-5">
            <h4 class="title is-6">Aplicações</h4>

            <b-table 
                v-if="resource.ambientes.length > 0"
                :data="resource.ambientes" 
                :striped="true" 
                :hoverable="true">

                <b-table-column label="Aplicação" v-slot="props">
                    {{ props.row.application_name }}
                </b-table-column>

                <b-table-column label="Ambiente" v-slot="props">
                    {{ props.row.ambiente }}
                </b-table-column>
                
                <b-table-column label="Data Vínculo" v-slot="props">
                    {{ new Date(props.row.pivot.created_at).toLocaleDateString("pt-BR") }}
                </b-table-column>
            </b-table>
        </div>
        <div v-else>
            <div class="notification is-warning">
                <i class="fa-solid fa-circle-info fa-fw mr-1"></i> Nenhuma aplicação vinculada a este token.
            </div>
        </div>

        <!-- Scopes -->
        <div class="b-actions mb-5 is-flex is-justify-content-end">
            <b-button
                label="Víncular Escopo"
                type="is-primary"
                size="is-medium"
                @click="linkScope" />
        </div>

        <div v-if="resource.scopes" class="box mt-5">
            <h4 class="title is-6">Escopos</h4>

            <b-table 
                v-if="resource.scopes.length > 0"
                :data="resource.scopes" 
                :striped="true" 
                :hoverable="true" >

                <b-table-column label="Aplicação" v-slot="props">
                    {{ props.row.application_name}}
                </b-table-column>
                
                <b-table-column label="Escopo" v-slot="props">
                    {{ props.row.scope }}
                </b-table-column>

                <b-table-column label="Data criação" v-slot="props">
                    <strong>{{ new Date(props.row.created_at).toLocaleDateString("pt-BR") }}</strong>
                </b-table-column>

            </b-table>
            <div v-else>
                <div class="notification is-warning">
                    <i class="fa-solid fa-circle-info fa-fw mr-1"></i> Nenhum scope vinculado a esta aplicação.
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
import Attribute from '@/components/Layout/Attribute.vue';
import LinkApplicationView from './LinkApplication.vue';
import LinkScopeView from './LinkScope.vue';

@Component({
    components: {
        Attribute
    },
})

export default class Show extends Vue { 
    data() {
        return {
            id: this.$route.params.id,
            resource: null, 
            isLoading: false,
        }
    }

    mounted() {
        this.isLoading = true;

        this.get()
    }

    get() {
        this.isLoading = true;

        this.$http.post('v1/movida/token/show/' + this.id)
            .then(res => {
                this.resource = res.data.data
            }).catch(err => {                
                this.flash('danger', err.body.message)
            })
            .finally(()=>{
                this.isLoading = false;
            });
    }

    linkApplication() {
        this.$buefy.modal.open({
            parent: this,
            component: LinkApplicationView,
            props: {
                title: 'Víncular Aplicação',
                token: this.resource
            },
            hasModalCard: true,
            trapFocus: true,
            events: {
                'refreshList': value => {
                    this.get()
                }
            }
        })
    }

    linkScope() {
        this.$buefy.modal.open({
            parent: this,
            component: LinkScopeView,
            props: {
                title: 'Víncular Escopo',
                token: this.resource
            },
            hasModalCard: true,
            trapFocus: true,
            events: {
                'refreshList': value => {
                    this.get()
                }
            }
        })
    }
}
</script>