export default {
    list: { method: 'post', url: 'v1/movida/token/list' },
    show: { method: 'post', url: 'v1/movida/token/show' },
    register: { method: 'post', url: 'v1/movida/token/register' },
    edit: { method: 'post', url: 'v1/movida/token/edit' },
    delete: { method: 'post', url: 'v1/movida/token/delete' },
    linkApplication: { method: 'post', url: 'v1/movida/token/link_application' },
    unlinkApplication: { method: 'post', url: 'v1/movida/token/unlink_application' },
    linkScope: { method: 'post', url: 'v1/movida/token/link_scope' },
    unlinkScope: { method: 'post', url: 'v1/movida/token/unlink_scope' },
}