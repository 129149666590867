<template>
    <layout 
        :title="application.Aplicacao" 
        :isLoading="isLoading"
        subtitle="Aplicações"
        titleIcon="rocket"
    >
        <div class="box mt-5">
            <h4 class="title is-6">Informações da aplicação</h4>
            <div class="columns">
                <div class="column is-four-fifths">
                    <attribute title="Descrição">
                        {{ application.Aplicacao }}
                    </attribute>
                </div>
                <div class="column">
                    <attribute title="Ativo?">
                        {{ application.Status ? 'Sim' : 'Não' }}
                    </attribute>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <attribute title="Adicionado em">
                        {{ new Date(application.Created_at).toLocaleDateString("pt-BR") }}
                    </attribute>
                </div>
                <div class="column">
                    <attribute title="Alterado em">
                        {{ new Date(application.Updated_at).toLocaleDateString("pt-BR") }}
                    </attribute>
                </div>
            </div>
        </div>

        <div class="mb-5 is-flex is-justify-content-end">
            <b-button
                label="Novo Ambiente"
                type="is-primary"
                size="is-medium"
                @click="createAmbiente" />
        </div>

        <!-- Ambientes -->
        <div v-if="application.ambientes" class="box mt-5">
            <h4 class="title is-6">Ambientes</h4>

            <b-table 
                v-if="application.ambientes.length > 0"
                :data="application.ambientes" 
                :striped="true" 
                :hoverable="true" >

                <b-table-column label="Nome" v-slot="props">
                    {{ props.row.ambiente }}
                </b-table-column>
                
                <b-table-column label="Status" v-slot="props">
                    <b-tag v-if="props.row.status == 1" type="is-success" rounded>Ativo</b-tag>
                    <b-tag v-else type="is-danger" rounded>Inativo</b-tag>
                </b-table-column>

                <b-table-column label="Data criação" v-slot="props">
                    <strong>{{ new Date(props.row.created_at).toLocaleDateString("pt-BR") }}</strong>
                </b-table-column>

                <b-table-column label="Ações" centered v-slot="props">
                    <b-tooltip label="Excluir" type="is-dark">
                        <a href="#" @click="deleteAmbiente(props.row.id)"><i class="fa-solid fa-trash fa-fw"></i></a>
                    </b-tooltip>
                </b-table-column>
            </b-table>

            <div v-else>
                <div class="notification is-warning">
                    <i class="fa-solid fa-circle-info fa-fw mr-1"></i> Nenhum ambiente vinculado a esta aplicação.
                </div>
            </div>
        </div>
        <div class="mb-5 is-flex is-justify-content-end">
            <b-button
                label="Novo Escopo"
                type="is-primary"
                size="is-medium"
                @click="createScope" />
        </div>

        <!-- Scopes -->
        <div v-if="application.ambientes" class="box mt-5">
            <h4 class="title is-6">Escopos</h4>

            <b-table 
                v-if="application.scopes.length > 0"
                :data="application.scopes" 
                :striped="true" 
                :hoverable="true" >

                <b-table-column label="Nome" v-slot="props">
                    {{ props.row.scope }}
                </b-table-column>

                <b-table-column label="Data criação" v-slot="props">
                    <strong>{{ new Date(props.row.created_at).toLocaleDateString("pt-BR") }}</strong>
                </b-table-column>

                <b-table-column label="Ações" centered v-slot="props">
                    <b-tooltip label="Edit" type="is-dark">
                        <a href="#" @click="editScope(props.row)"><i class="fa-solid fa-edit fa-fw"></i></a>
                    </b-tooltip>
                </b-table-column>
            </b-table>

            <div v-else>
                <div class="notification is-warning">
                    <i class="fa-solid fa-circle-info fa-fw mr-1"></i> Nenhum escopo vinculado a esta aplicação.
                </div>
            </div>
        </div>

    </layout>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
import Attribute from '@/components/Layout/Attribute.vue';
import CreateAmbienteView from '@/modules/ambiente/pages/Create.vue';
import CreateScopeView from '@/modules/scope/pages/Create.vue';
import EditScopeView from '@/modules/scope/pages/Edit.vue';

@Component({
    components: {
        Attribute
    },
})

export default class show extends Vue { 
  data() {
    return {
      id: this.$route.params.id,
      application: null, 
      isLoading: false
    }
  }

  mounted() {
    this.get()
  }

  get() {
    this.isLoading = true;

    this.$http.post('v1/movida/application/show/' + this.id)
      .then(res => {
          this.application = res.data.data
      })
      .catch(err => {
          this.flash('danger', err.body.message)
      })
      .finally(()=>{
          this.isLoading = false;
      });
  }

  createAmbiente() {
    this.$buefy.modal.open({
      parent: this,
      component: CreateAmbienteView,
      props: {
        title: 'Novo Ambiente',
        aplicacao: this.application
      },
      hasModalCard: true,
      trapFocus: true,
      events: {
        'refreshList': value => {
          this.get()
        }
      }
    })
  }

  deleteAmbiente(id) {
    this.$buefy.dialog.confirm({
      title: 'Excluir Ambiente',
      message: 'Tem certeza que deseja <b>excluir</b>?',
      confirmText: 'Excluir',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => {
        this.isLoading = true;

        this.$http.delete('v1/movida/ambiente/delete/' + id)
          .then(res => {
            this.$root.flashSuccess('Ambiente removido com sucesso!');

            this.get();
          })
          .catch(err => {
            this.$root.flashError('Erro ao remover status da operação');
          })
          .finally(()=>{
            this.isLoading = false;
          });
      }
    })
  }

  createScope() {
    this.$buefy.modal.open({
      parent: this,
      component: CreateScopeView,
      props: {
        title: 'Novo Escopo',
        aplicacao: this.application
      },
      hasModalCard: true,
      trapFocus: true,
      events: {
        'refreshList': value => {
          this.get()
        }
      }
    })
  }

  editScope(scope) {
    this.$buefy.modal.open({
      parent: this,
      component: EditScopeView,
      props: {
        scope: scope
      },
      hasModalCard: true,
      trapFocus: true,
      events: {
        'refreshList': value => {
          this.list()
        }
      }
  })
  }
}
</script>