<template>
    <layout 
        title="Aplicações" 
        :isLoading=isLoading
        subtitle="Inclusão de aplicações das integrações"
        titleIcon="rocket"
    >
        <div slot="actions">
            <b-button
                label="Nova Aplicação"
                type="is-primary"
                size="is-medium"
                @click="create" />
        </div>
        <table-default
            :data="aplicacoes"
            :columns="columns"
            @edit="edit"
            >
            <template v-for="column in columns">
                <b-table-column :key="column.id" v-bind="column" sortable>
                    <template
                        v-if="column.searchable && !column.numeric"
                        #searchable="props">
                        <b-input
                            v-model="props.filters[props.column.field]"
                            placeholder="Pesquisar..." />
                    </template>
                    <template v-slot="props">
                        <!-- Caso a coluna seja status, alterar o boolean por texto e adicionar tag -->
                        <span v-if="column.field == 'Status'">
                            <b-tag v-if="props.row[column.field] == 1" type="is-success" rounded>Ativo</b-tag>
                            <b-tag v-else type="is-danger" rounded>Inativo</b-tag>
                        </span>
                        <span v-else>
                            <router-link :to="'/application/' + props.row.AplicacaoID">
                                {{ props.row[column.field] }}
                            </router-link>
                        </span>
                    </template>
                </b-table-column>
            </template>
            <b-table-column v-slot="props" field="" label="Ações" sortable>
                <b-dropdown aria-role="list">
                    <template #trigger="{ }">
                        <b-button
                            label="..."
                            type="is-text"
                            />
                    </template>
                    <b-dropdown-item class="has-text-left" aria-role="listitem"><router-link :to="'/application/' + props.row.AplicacaoID">Visualizar</router-link></b-dropdown-item>
                    <b-dropdown-item class="has-text-left" aria-role="listitem" @click="edit(props.row)">Editar</b-dropdown-item>
                    <!-- <b-dropdown-item class="has-text-left" aria-role="listitem">Excluir</b-dropdown-item> -->
                </b-dropdown>
            </b-table-column>
        </table-default>
    </layout>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
import TableDefault from '@/components/Layout/TableDefault.vue';
import CreateView from './Create.vue';
import EditView from './Edit.vue';
import services from '@/http';

@Component({
    components: {
        TableDefault
    },
})

export default class Index extends Vue { 
    data() {
        return {
            aplicacoes: [],
            columns: [
                {
                    field: 'AplicacaoID',
                    label: 'ID',
                    width: '100',
                    numeric: true,
                    searchable: true,
                },
                {
                    field: 'Aplicacao',
                    label: 'Nome',
                    searchable: true,
                },
                {
                    field: 'Status',
                    label: 'Status',
                }
            ],
            isLoading: false
        }
    }

    mounted() {
        this.list()
    }

    list() {
        this.isLoading = true;

        services.application.list()
            .then(res => {
                this.aplicacoes = res.data.data;
            }).catch(err => {
                this.$root.flashError(err)
            })
            .finally(()=>{
                this.isLoading = false;
            });
    }

    create() {
        this.$buefy.modal.open({
            parent: this,
            component: CreateView,
            hasModalCard: true,
            trapFocus: true,
            events: {
                'refreshList': value => {
                    this.list()
                }
            }
        })
    }

    edit(data) {
        this.$buefy.modal.open({
            parent: this,
            component: EditView,
            props: {
                aplicacao: data
            },
            hasModalCard: true,
            trapFocus: true,
            events: {
                'refreshList': value => {
                    this.list()
                }
            }
        })
    }
}
</script>
