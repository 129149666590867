import store from '@/store';
import { userAuth } from '@/modules/auth';

export default async (to, from, next) => {
  document.title = process.env.VUE_APP_TITLE

  if (to.matched.some((record) => record.meta.title)) {
    document.title = `${process.env.VUE_APP_TITLE} - ${to.meta.title}`
  }

  if (window.location.search.includes("token=")) {        
    const params = new URL(location.href).searchParams;
    const token = params.get('token');     
    userAuth.setToken(token);
  }

  if (to.name !== 'auth' && !userAuth.hasTokenCookie()){
    next({ name: 'auth' })
  } else {
    next()
  }

  if (!userAuth.hasTokenState()) {
    await store.dispatch('auth/ActionSetToken', {
      token: userAuth.getToken(),
    })
  }

  if (!userAuth.isAuthenticated()) {
    await store.dispatch('auth/ActionLoadSession')
  }

  if (to.name == 'root') {
    if (userAuth.isAuthenticated()) {
      next({ name: 'application' })
    } else {
      next({ name: 'auth' })
    }
  }

  if (!_.isEmpty(to.meta.permission)) {
    if (_.includes(userAuth.getPermissions(), to.meta.permission) || _.includes(userAuth.getPermissions(), 'all')) {
      next()
    } else {
      next({ name: 'forbidden' })
    }
  }

  next()
}
