import { Component } from 'vue-property-decorator';

import MenuItens from '@/components/MenuItens/MenuItens.vue';

@Component({
  components: {
    MenuItens
  },
})

export default class SidebarComponent {
  data() {
    return {
      open: true,
      overlay: false,
      fullheight: true,
      fullwidth: false,
      right: false
    };
  }

};