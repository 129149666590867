import { services as auth } from '@/modules/auth';
import { services as application } from '@/modules/application';
import { services as ambiente } from '@/modules/ambiente';
import { services as token } from '@/modules/token';
import { services as profile } from '@/modules/profile';
import { services as scope } from '@/modules/scope';

export default {
    auth,
    application,
    ambiente,
    token,
    profile,
    scope
}