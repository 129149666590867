import Vue from 'vue';
import VueResource from 'vue-resource';
import services from './services';
import _ from 'lodash';

Vue.use(VueResource);

const http = Vue.http

http.options.root = process.env.VUE_APP_BFF_URL;

Object.keys(services).map(service => {
  services[service] = Vue.resource('',{}, services[service])
})

http.interceptors.push((request) => {
  if (_.isNull(request.headers.get("Authorization")) && $cookies.get("token_user")) {
    request.headers.set("Authorization", `Bearer ${$cookies.get("token_user")}`)
  }  
});

export { http }
export default services;