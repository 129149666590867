<template>
    <modal-crud
        title="Editar Token"
        v-on:close="$emit('close')">
        <section class="modal-card-body">
            <b-field label="Nome">
                <b-input
                    type="text"
                    placeholder="Nome"
                    v-model="aplicacao.Aplicacao"
                    required>
                </b-input>
            </b-field>
        </section>
        <div slot="buttons">
            <b-button
                label="Salvar"
                type="is-primary"
                @click="update" />
        </div>
    </modal-crud>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import ModalCrud from '@/components/Layout/ModalCrud.vue';
import state from '@/modules/auth/store/state';
import services from '@/http';

@Component({
    components: {
        //mixins: [flash],
        "modal-crud":ModalCrud,
    },
})

export default class Edit extends Vue { 
    @Prop({default: {}}) aplicacao

    data() {
        return {}
    }

    update() {
        var data = {
            id: this.aplicacao.AplicacaoID,
            application_name: this.aplicacao.Aplicacao,
            userId: state.user.id            
        }

        services.application.edit(data)
            .then(res => {
                this.$root.flashSuccess('Alterado com sucesso!')

                this.$emit('close');               
                this.$emit('refreshList');               
            }).catch(err => {
                this.$root.flashError(err)
            });
    }
}
</script>