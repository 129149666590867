<template>
    <modal-crud
        :title=title
        v-on:close="$emit('close')"
        :isLoading=isLoading>
        <section class="modal-card-body">
            <!-- <div v-for="(aplicacao) in aplicacoes" v-bind:key="aplicacao.AplicacaoID" class="mb-4">
                <b-dropdown
                    v-model="ambientesSelecionados[aplicacao.AplicacaoID]"
                    :scrollable="true"
                    :max-height="200"
                    multiple
                    >
                    <template #trigger>
                        <b-button expanded>
                            <i class="fa-solid fa-circle-chevron-down fa-fw mr-1"></i> <strong>{{ aplicacao.Aplicacao }}</strong> {{ ambientesSelecionados[aplicacao.AplicacaoID] ? ambientesSelecionados[aplicacao.AplicacaoID].length : 0}} escolhido(s)
                        </b-button>
                    </template>

                    <b-dropdown-item v-for="ambiente in aplicacao.ambientes" :value="ambiente.id" v-bind:key="ambiente.id">
                        {{ ambiente.ambiente }}
                    </b-dropdown-item>

                </b-dropdown>
            </div> -->
            <pick-list v-model="aplicacoes" listStyle="height:342px" dataKey="AplicacaoID">
                <template #sourceheader>
                    Available
                </template>
                <template #targetheader>
                    Selected
                </template>
                <template #item="slotProps">
                    <div class="p-caritem">
                        <div>
                            <span>{{slotProps.item.AplicacaoID}} - {{slotProps.item.Aplicacao}}</span>
                        </div>
                    </div>
                </template>
            </pick-list>
        </section>
        <div slot="buttons">
            <b-button
                label="Salvar"
                type="is-primary"
                @click="sync" />
        </div>
    </modal-crud>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import ModalCrud from '@/components/Layout/ModalCrud.vue';
import state from '@/modules/auth/store/state';
import services from '@/http';
import PickList from 'primevue/picklist';
import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";

@Component({
    components: {
        "modal-crud":ModalCrud,
        "pick-list":PickList,
    },
})

export default class LinkApplication extends Vue { 
    @Prop({default: {}}) token
    @Prop({default: ''}) title

    data() {
        return {
            isLoading: false,
            ambientesSelecionados: [],
            aplicacoes: []
        }
    }

    mounted() {
        this.isLoading = true;

        this.getAplicacoes()
    }

    getAplicacoes() {
        this.isLoading = true;

        if (this.token.ambientes) {
            this.token.ambientes.forEach(el => {
                if(!this.ambientesSelecionados[el.aplicacao_id]) {
                    this.ambientesSelecionados[el.aplicacao_id] = [];
                }

                this.ambientesSelecionados[el.aplicacao_id].push(el.id);
            });
        }

        services.application.list()
            .then(res => {
                this.isLoading  = false;
                this.aplicacoes = [res.data.data, []];
            }).catch(err => {
                this.isLoading = false;
                
                this.flash('danger', err.body.message)
            })
            .finally(()=>{
                this.isLoading = false;
            });
    }

    sync() {
        var data = {
            client_id: this.token.id,
            ambientes: this.aplicacoes[1],
            userId: state.user.id            
        }

        this.isLoading = true;

        services.token.linkApplication(data)
            .then(res => {
                this.$root.flashSuccess('Vínculado com sucesso!')

                this.$emit('close');               
                this.$emit('refreshList');               
            }).catch(err => {
                this.$root.flashError(err)
            })
            .finally(()=>{
                this.isLoading = false;
            });
    }
}
</script>