import _ from 'lodash';
import store from '@/store';

import { 
    getCookieToken, 
    setCookieToken, 
    getLocalCheckToken, 
    setLocalCheckToken,
    deleteCookieToken
} from '@/modules/auth/storage';

let data = {
    get token() { return getCookieToken() },
    set token(value) { return setCookieToken(value) },

    get checkToken() { return getLocalCheckToken() },
    set checkToken(value) { return setLocalCheckToken(value) },
}

const userAuth = {

    user: null,

    clear() {
        this.user = null
        deleteCookieToken()
    },

    /**
     * Desautentica o usuário atualmente autenticado na aplicação
     */
    logout() {
        this.clear()
    },

    /**
     * Verifica se usuário está autenticado e com seus dados disponíveis
     */
    isAuthenticated() {
        return this.hasUser() && this.hasTokenState()
    },

    hasUser(){
        return !this.isEmpty(store.getters['auth/user'])
    },

    hasTokenState(){
        return store.getters['auth/hasToken'];
    },

    hasTokenCookie() {
        return !this.isEmpty(this.getToken());
    },

    setCheckToken(value) {
        data.checkToken = value
    },

    getToken() {
        return getCookieToken()
    },

    setToken(token) {
        data.token = token
    },

    deleteToken() {
        deleteCookieToken()
    },

    getPermissions() {
        return store.getters['auth/scopes']
    },

    setHeaderToken(token) {
        setHeaderToken(token)
    },

    isEmpty(value) {
        return _.isEmpty(value);
    }
}

export default userAuth