import * as types from './mutation-types';

export default {
    [types.SET_USER] (state, payload){
        state.user = payload
    },
    [types.SET_USER_SCOPES] (state, payload){
        state.user.scopes = payload
    },
    [types.SET_TOKEN] (state, payload){
        state.token = payload
    },
    [types.SET_APP_TOKEN] (state, payload){
        state.token = payload
    },
    [types.DELETE_TOKEN] (state){     
        state.token = "";
    }
}