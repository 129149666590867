<template>
  <div class="p-1">
    <b-menu>
      <b-menu-list label="Usuário">
        <b-menu-item
          tag="a"
          :href="authUrl + '/profile'"
          icon="user"
          label="Perfil"
        />
      <b-menu-item
          tag="a"
          :href="authUrl + '/mfa'"
          icon="user-shield"
          label="MFA"
        />
      </b-menu-list>
      <b-menu-list label="Cadastros">
        <b-menu-item icon-pack="fas" tag="router-link" to="/application" icon="rocket" label="Aplicações"/>
        <b-menu-item tag="router-link" to="/token" icon="key" label="Tokens" />
        <b-menu-item tag="router-link" to="/profile" icon="users" label="Perfis" />
      </b-menu-list>
      <b-menu-list label="Ações">
        <b-menu-item
          icon="right-from-bracket"
          @click="submit"
          label="Logout"
        ></b-menu-item>
      </b-menu-list>
    </b-menu>
  </div>
</template>

<style src="./MenuItens.css">
</style>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      authUrl: process.env.VUE_APP_AUTH_URL
    };
  },
  methods: {
    ...mapActions("auth", ["ActionSignOut"]),
    submit() {
      this.ActionSignOut().then((res) => {
        this.$router.push("/auth");
      });
    },
  },
};
</script>
