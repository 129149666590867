<template>
  <modal-crud
    title="Novo Scope"
    v-on:close="$emit('close')"
    :isLoading=isLoading>
    <template>
        <section class="modal-card-body">
            <b-field label="Nome">
                <b-input
                    type="text"
                    placeholder="Nome"
                    v-model="nome"
                    required>
                </b-input>
            </b-field>
        </section>
        <div slot="buttons">
            <b-button
                label="Salvar"
                type="is-primary"
                @click="save" />
        </div>
    </template>
  </modal-crud>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import ModalCrud from '@/components/Layout/ModalCrud.vue';
import state from '@/modules/auth/store/state';
import services from '@/http';
import Attribute from '@/components/Layout/Attribute.vue';

@Component({
    components: {
        "modal-crud":ModalCrud,
        Attribute
    },
})

export default class Create extends Vue { 
  @Prop({default: {}}) aplicacao
  @Prop({default: ''}) title

  data() {
    return {
      nome: null,
      isLoading: false,
    }
  }

  save() {
    var data = {
      aplicacao_id: this.aplicacao.AplicacaoID,
      scope: this.nome,
      userId: state.user.id           
    }

    this.isLoading = true;

    services.scope.register(data)
      .then(res => {
          this.token = res.body.data         

          this.$root.flashSuccess('Adicionado com sucesso!');

          this.$emit('close');               
          this.$emit('refreshList');  
      })
      .catch(err => {
          this.$root.flashError(err)
      })
      .finally(()=>{
          this.isLoading = false;
      });
  }
}
</script>